import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/Layout"
import HeadSection from "../../components/HeadSection"
import Header from "../../components/Home/Header"

import OfferSection from "../../components/Home/OfferSection"
import PriceActions from "../Home/PriceActions"

const PricePage = ({ data }) => {
  return (
    <>
      <HeadSection
        title="Цены на металллические катализаторы"
        description="Каталог металллические катализаторов, которые мы выкупили у частных клиентов. Цены на них и данные по автомобилю, являющегося донором катализатора."
      />
      <Layout>
        <div className="screen">
          <Header />
        </div>
        <section className="price" style={{ paddingTop: "50px" }}>
          <div className="container mt-4" style={{ fontSize: "16px" }}>
            <Link to="/" style={{ color: "#ff8a2c" }}>
              Главная
            </Link>{" "}
            /
            <Link style={{ color: "#ff8a2c" }} to="/metallicheskie">
              {" "}
              Металлические катализаторы
            </Link>{" "}
            / Катализатор {data.name}
            <div
              className="price-cards"
              style={{ paddingBottom: "15px", paddingTop: "63px" }}
            >
              <div className="row px-4">
                <div className="col-12 col-md-6">
                  <img
                    src={data.img}
                    alt={`Катализатор от ${data.name}`}
                    style={{ marginBottom: "30px" }}
                  />
                </div>
                <div className=" col-12 col-md-6 max-w-3xl">
                  <h1 className="section-title section-title--extra-bold price-title text-left">
                    {data.name}
                  </h1>
                  <h3 className="price-subtitle text-left mb-5">
                    Катализатор {data.name} (выкуп у частного лица)
                  </h3>
                  <div className="calc-footer__row mb-5">
                    <div className="calc-footer__info">год выпуска:</div>
                    <div className="calc-footer__cost">{data.year}</div>
                  </div>
                  <div className="calc-footer__row mb-5">
                    <div className="calc-footer__info">объем двигателя:</div>
                    <div className="calc-footer__cost">{data.engine}</div>
                  </div>
                  <div className="calc-footer__row mb-5">
                    <div className="calc-footer__info">тип двигателя:</div>
                    <div className="calc-footer__cost">{data.type}</div>
                  </div>
                  <div className="calc-footer__row mb-5">
                    <div className="calc-footer__info">Выплата составила</div>
                    <div className="calc-footer__cost">{data.price} ₽*</div>
                  </div>
                </div>
                <div className="col-12">
                  <div
                    className="delivery-title  py-3"
                    style={{ fontSize: "20px", transform: "translateY(0px)" }}
                  >
                    {" "}
                    Дата публикации: {data.date}г.
                  </div>
                </div>
                <div className="col-12">
                  <h3 className="price-card__row text-left  mt-3 mb-5">
                    *Цена действительна на дату публикации поста и учитывает
                    текущие котировки биржи металлов. Точная стоимость
                    определяется с помощью анализатора. <br />
                    <br />
                    <b>Оптовым клиентам повышенный процент выплаты</b>,
                    обсуждение условий индивидуально
                  </h3>
                </div>
              </div>
            </div>
            <PriceActions />
          </div>
        </section>

        <OfferSection />
      </Layout>
    </>
  )
}

export default PricePage
